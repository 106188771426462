<template>
	<div id="deliveryDetail">
		<page-delivery-detail :is-shop="0"></page-delivery-detail>
	</div>
</template>

<script>
	import pageDeliveryDetail from '@/components/layout/land/page-delivery-detail.vue'
	export default{
		components:{
			pageDeliveryDetail
		}
		
	}
</script>

<style lang="scss">
</style>
